import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import propTypes from 'prop-types';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Carousel from '../components/Carousel';

import indexStyles from './index.module.scss';

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBanner(sort: { order: ASC, fields: order }) {
        edges {
          node {
            id
            title
            subHeading
            slide {
              description
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
      allContentfulIndex {
        edges {
          node {
            title
            content {
              childMarkdownRemark {
                html
              }
            }
            content2 {
              childMarkdownRemark {
                html
              }
            }
            professional {
              image {
                description
                fluid {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `);
  const { content, content2 } = data.allContentfulIndex.edges[0].node;
  const { professional } = data.allContentfulIndex.edges[0].node;

  const jobs = professional.map((image, i) => {
    return (
      <div key={i} className="column">
        <div className={indexStyles.imageBox}>
          <div className={indexStyles.image}>
            <Img
              fluid={image.image[0].fluid}
              alt={image.image[0].description}
            />
          </div>
          <div className={indexStyles.imageDescription}>
            {image.image[0].description}
          </div>
        </div>
      </div>
    );
  });

  return (
    <Layout>
      <SEO
        title="Pest Exterminator - Los Angeles, CA"
        pathname={location.pathname}
        keywords={[
          `termite control`,
          `los angeles`,
          `fumigation`,
          `pest control`,
        ]}
      />
      <div>
        <Carousel CarouselData={data.allContentfulBanner.edges} />
        <div className={indexStyles.container}>
          <div className="columns">
            <div className="column">
              <div
                style={{ padding: '1rem' }}
                dangerouslySetInnerHTML={{
                  __html: content.childMarkdownRemark.html,
                }}
              />
            </div>
            <div className="column">
              <div
                style={{ textAlign: 'center', padding: '1rem' }}
                dangerouslySetInnerHTML={{
                  __html: content2.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </div>
        <div className={indexStyles.imageBoxes}>
          <h3>Professional Jobs</h3>
          <div className="columns">{jobs}</div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

IndexPage.propTypes = {
  location: propTypes.shape({
    pathname: propTypes.string,
  }),
};

IndexPage.defaultProps = {
  location: {},
};
